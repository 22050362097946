import websdk from 'easemob-websdk'
import emedia from 'easemob-emedia'
import config from './WebIMConfig'

// 初始化IM SDK
export function initWebIM(websdk, emedia, webrtc) {
  let WebIM = {}
  WebIM = window.WebIM = websdk
  WebIM.config = config
  // eslint-disable-next-line new-cap
  WebIM.conn = new WebIM.connection({
    appKey: WebIM.config.appkey,
    isHttpDNS: WebIM.config.isHttpDNS,
    isMultiLoginSessions: WebIM.config.isMultiLoginSessions,
    https: WebIM.config.https,
    host: WebIM.config.Host,
    url: WebIM.config.xmppURL,
    apiUrl: WebIM.config.apiURL,
    isAutoLogin: true,
    heartBeatWait: WebIM.config.heartBeatWait,
    autoReconnectNumMax: WebIM.config.autoReconnectNumMax,
    autoReconnectInterval: WebIM.config.autoReconnectInterval,
    isStropheLog: WebIM.config.isStropheLog,
    delivery: WebIM.config.delivery
  })
  if (!WebIM.conn.apiUrl) {
    WebIM.conn.apiUrl = WebIM.config.apiURL
  }

  // 多人视频
  if (emedia) {
    WebIM.EMedia = emedia
    WebIM.EMedia.config({
      // 配置服务器域名、必填 比如: 'https://a1.easemob.com'
      restPrefix: WebIM.config.apiURL,
      // 从环信后台 获取的appkey、必填
      appkey: WebIM.config.appkey,
      // boolean 是否开启打印日志，默认true
      consoleLogger: process.env.NODE_ENV === 'development'
    })
  }

  // 单人1v1视频
  if (webrtc) {
    WebIM.WebRTC = webrtc
  }
  return WebIM
}

export const WebIM = initWebIM(websdk, emedia)
